.top-to-btm{
    position: relative;
  }
  .icon-position{
    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 20;
  }
  .icon-style{
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color:#B7883C;
    border: 2px solid #fff;
    border-radius: 50%;
    height: 124px;
    width: 124px;
    color: #fff;
    padding: 10px 15px;
    cursor: pointer;
    animation: movebtn 3s ease-in-out infinite;
    transition: all .5s ease-in-out;
    .done{
      width: 30px;
      height: 30px;
    }
  }
  .icon-style:hover{
    animation: none;
    background: #B7883C;
    opacity: 0.9;
    border: 2px solid #B7883C;
    .done{
      color: #B7883C;
      fill: #B7883C;

    }
  }


  .text{
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
  }
  @keyframes movebtn {
    0%{
      transform: translateY(0px);
    }
    25%{
      transform: translateY(20px);
    }
    50%{
      transform: translateY(0px);
    }
    75%{
      transform: translateY(-20px);
    }
    100%{
      transform: translateY(0px);
    }
  }

  .width{
    width: 500px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    padding-top: 20px;

  }