.root {
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

}
.banner{
  position: absolute;
  background-image: url("/assets/baner/baner.png");
  // object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  height: 868px;
}



.containerText{
    max-width: 892px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.containerButton{
    display: flex;
    flex-direction: column;
    gap: 20px;
    .rowButton{
      display: flex;
      flex-direction: row;
      gap: 24px;
    }
}
.CardButton{
  margin-top: 124px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 48px;
}
.preTitle {
  font-weight: 700;
  font-size: 19px;
  line-height: 32px;
  color: #fff;
}
.title {
  font-weight: 900;
  font-size: 50px;
  line-height: 56px;
  color: #fff;
}
.textButton {
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
}

@media only screen and (min-width: 824px) and (max-width: 1100px) {

   .banner{
    height: 720px;
  }

  .title {
    font-weight: 900;
    font-size: 44px;
    line-height: 60px;
    color: #fff;
  }

  .CardButton{
      margin-top: 84px;
      display: flex;
      flex-direction: row;
      gap: 40px;
  }
  
}

@media only screen and (min-width: 600px) and (max-width: 824px) {
  .root {
    gap: 20px;
  }
  .banner{
    position: absolute;
    height: 550px;
  }
  .containerText{
      max-width: 400px;
      display: flex;
      flex-direction: column;
      gap: 20px;
  }
  .containerButton{
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
  }
  .CardButton{
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      gap: 16px;
  }
  .preTitle {
    font-weight: 700;
    font-size: 19px;
    line-height: 32px;
    color: #fff;
  }
  .title {
    font-weight: 900;
    font-size: 36px;
    line-height: 50px;
    color: #fff;
  }
  .textButton {
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 600px) {

  .banner{
    position: absolute;
    background-image: url("/assets/baner/mobileBaner.png");
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    height: 320px;
  }

  .containerText{
    margin-top: -30px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    margin-bottom: 40px;
}
.title {
  font-weight: 800;
font-size: 25px;
line-height: 40px;
text-align: center;
}
  .containerButton{
    display: none;
  }

  .CardButton{
      margin-top: 84px;
      display: flex;
      flex-direction: row;
      gap: 40px;
      display: none;
  }
  
}
