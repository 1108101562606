
.root {
 
  transition: 100ms linear;
  
  align-items: center;
  cursor: pointer;
}

.contained {
  padding: 12px 24px; 
  background: #B7883C;
  color: #ffffff;
}
// .contained:hover{
//   background: #B7883C;
//   opacity: 0.7;
// }
// .contained:active{
//   background: #B7883C;
// }
.outLine{
  background-color: transparent;
  border: 1px solid #1C7C54;
  color:  #B7883C;
  padding: 12px 24px;
}


.text {
  background: none;
  border-color: transparent;
  color: #fff;
  &:hover{
    background: #fff;
  }
  &:active{
    background: #fff;
  }
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed;
  &.contained  {
    background: #6C717A
;
    color: #6C717A
;
    border-color: #6C717A
;
    path {
      color: #6C717A
;
    }
  }
  &.outlined {
    background: transparent;
    color: #B7883C;
    border-color:#B7883C;
    path {
      color: #B7883C;
    }
  }
  &.text {
    background: transparent;
    color: #B7883C;
    border-color: transparent;
    path {
      color: #B7883C;
    }
  }
}


.content {
  display: flex;
  font-weight: 800;
  font-size: 13px;
  line-height: 20px;
  justify-content: center;
}
.small {
  padding: 16px 75px; 
}


.middle {
  padding: 16px 37px; 
}

.BanerRegistration {
  padding: 12px 25px; 
}

.tiny {
  padding: 4px 16px; 
  cursor: auto;
}

@media only screen and (min-width: 820px) and (max-width: 1100px) {
 
  .contained {
    padding: 12px 24px;
  }
  .content {
  font-weight: 800;
font-size: 13px;
line-height: 20px;
  }
  .tiny {
    padding: 4px 16px; 
    cursor: auto;
  }
}

@media only screen and (min-width: 600px) and (max-width: 820px) {
 
  .contained {
    padding: 7px 10px;
  }
  .content {
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
  }
  .tiny {
    padding: 4px 16px; 
    cursor: auto;
  }
}

@media only screen and (min-width: 320px) and (max-width: 720px) {
  .contained {
    padding: 12px 20px;
    border-radius: 8px;
  }
  
  .outLine{
    padding: 12px 20px;
    border-radius: 8px;
  }
}
// .clean_icon {
//   .before_icon, .after_icon {
//     margin: 0;
//   }
// }
