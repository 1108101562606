.Contaierslider{
  position: relative;
  width: 624px;
  height: 352px;
}
.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 10px;
    background: #fff;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .icon{
    width: 24px;
    height: 24px;
  }
  
  /* new styles added */
  .swiperButton {
    position: absolute;
    top: 80%;
    z-index: 1000;
    cursor: pointer;
  }
  .swiperButton svg {
    width: 24px;
    height: 24px;
  }
  .image-swiperButton-prevfirst {
    left: 80%;
  }
  .image-swiperButton-prevsecond {
    left: 80%;
  }
  .image-swiperButton-prevthree {
    left: 80%;
  }
  .image-swiperButton-prevfour {
    left: 80%;
  }
  .image-swiperButton-nextfirst {
    right: 30px;
  }
  .image-swiperButton-nextsecond {
    right: 30px;
  }
  .image-swiperButton-nextthree {
    right: 30px;
  }
  .image-swiperButton-nextfour {
    right: 30px;
  }
  .swiperButton-disabled {
    opacity: 0.5;
  }

  @media only screen and (min-width: 824px) and (max-width: 1100px) {
    .Contaierslider{
      position: relative;
      width: 464px;
      height: 380px;
    }
 
  }

  @media only screen and (min-width: 600px) and (max-width: 1100px) {
    
    .Contaierslider{
      position: relative;
      width: 292px;
      height: 240px;
    }

    .image-swiperButton-prevfirst {
      left: 70%;
    }
    .image-swiperButton-prevsecond {
      left: 70%;
    }
    .image-swiperButton-prevthree {
      left: 70%;
    }
    .image-swiperButton-prevfour {
      left: 70%;
    }
    .image-swiperButton-nextfirst {
      right: 20px;
    }
    .image-swiperButton-nextsecond {
      right: 20px;
    }
    .image-swiperButton-nextthree {
      right: 20px;
    }
    .image-swiperButton-nextfour {
      right: 20px;
    }
 
  }
  
@media only screen and (min-width: 272px) and (max-width: 600px) {
  .Contaierslider{
    position: relative;
    width: 320px;
    height: 240px;
  }

  .image-swiperButton-prevfirst {
    left: 75%;
  }
  .image-swiperButton-prevsecond {
    left: 75%;
  }
  .image-swiperButton-prevthree {
    left: 75%;
  }
  .image-swiperButton-prevfour {
    left: 75%;
  }
  .image-swiperButton-nextfirst {
    right: 20px;
  }
  .image-swiperButton-nextsecond {
    right: 20px;
  }
  .image-swiperButton-nextthree {
    right: 20px;
  }
  .image-swiperButton-nextfour {
    right: 20px;
  }
}

@media only screen and (min-width: 395px) and (max-width: 430px) {
  .Contaierslider{
    width: 380px;
  }
}