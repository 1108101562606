.card {
  display: grid;
  grid-template-columns: 1fr;
  padding: 28px;
  border: 1px solid #b7883c;
  border-radius: 2px;
  gap: 16px;
  max-height: max-content;
  height: 250px;
}

.link{
  cursor: pointer;
}
.cardContact{
  display: grid;
  grid-template-columns: 1fr;
  padding: 28px;
  border: 1px solid #b7883c;
  border-radius: 2px;
  gap: 16px;
}

.icon {
  width: 32px;
  height: 32px;
}
.title {
  font-weight: 700;
  font-size: 19px;
  line-height: 32px;
  color: #010101;
  text-align: justify;
}
.subTitle {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #010101;
  text-align: left;
  white-space: pre-wrap;
}

@media only screen and (min-width: 824px) and (max-width: 1100px) {
  .card {
    padding: 16px;
    gap: 16px;
  }

  .title {
    font-size: 19px;
    line-height: 32px;
    width: 144px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 824px) {
  .card {
    padding: 16px;
    gap: 12px;
    width: 160px;
    height: 184px;
  }

  .icon {
    width: 32px;
    height: 32px;
  }
  .title {
    font-weight: 700;
    font-size: 11px;
    line-height: 20px;
  }
  .subTitle {
    font-weight: 400;
    font-size: 10px;
    line-height: 24px;
  }
}
@media only screen and (min-width: 272px) and (max-width: 600px) {
  .card {
    display: grid;
    grid-template-columns: 1fr;
    padding: 12px;
    border: 1px solid #b7883c;
    border-radius: 2px;
    gap: 8px;
    width: 100%;
    height: 200px;
  }
  .cardContact{
    display: grid;
    grid-template-columns: 1fr;
    padding: 10px;
    border: 1px solid #b7883c;
    border-radius: 2px;
    gap: 16px;
  }

  .icon {
    width: 32px;
    height: 32px;
  }
  .title {
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
  }
  .subTitle {
    
    white-space: pre-wrap;
    font-weight: 400;
    font-size: 10px;
    line-height: 24px;

  }
}
