.card {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  padding: 24px 48px;
  align-items: center;
  border: 1px solid #b7883c;
  border-radius: 4px;
  gap: 24px;
  cursor: pointer;
}

.title2 {
  display: none;
}

.img {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%
}

.wrapProp {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.wrapText {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.color {
  color: #b7883c;
}

.title {
  display: block;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}
.block {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 154% */

  /* Text 1 */

  color: #010101;
}

@media only screen and (min-width: 820px) and (max-width: 1100px) {
  .card {
    padding: 16px;
    gap: 16px;
  }

  .title {
    font-size: 19px;
    line-height: 32px;
    width: 144px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 824px) {
  .card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 12px;
    align-items: center;
    border: 1px solid #b7883c;
    border-radius: 4px;
    gap: 12px;
  }
  .img {
    width: 120px;
    height: 120px;
  }
  .wrapProp {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
  .wrapText {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
  }
  .block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .color {
    color: #b7883c;
  }
  .title2 {
    display: block;
    font-weight: 700;
    font-size: 19px;
    line-height: 32px;
    color: #010101;
  }
  .title {
    display: none;
  }
  .block {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;

    /* Text 1 */

    color: #010101;
  }
}
@media only screen and (min-width: 272px) and (max-width: 600px) {
  .card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 12px;
    align-items: center;
    border: 1px solid #b7883c;
    border-radius: 4px;
    gap: 24px;
  }
  .img {
    width: 160px;
    height: 140px;
  }
  .wrapProp {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
  .wrapText {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 16px;
  }

  .block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .color {
    color: #b7883c;
  }
  .title2 {
    display: block;
    font-weight: 700;
    font-size: 19px;
    line-height: 32px;
    color: #010101;
  }
  .title {
    display: none;
  }
  .block {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #010101;
  }
}
