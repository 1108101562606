.Tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.Tab {
  padding: 12px 16px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.15s;
  &__selected {
    opacity: 1;
    background-color: #b7883c;
    border-bottom: 1px solid #b7883c;
  }

  &:hover {
    opacity: 0.9;
    color: #b7883c;
    // background-color: #B7883C;
  }

  &Label {
    color: #6c717a;

    &__selected {
      color: #fff;
    }
    &:hover {
      opacity: 0.9;
      // background-color: #B7883C;
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 824px) {
  .Tabs {
    gap: 5px;
  }

  .Tab {
    display: flex;
    width: 100%;
    padding: 5px 5px;
    justify-content: center;
    &Label {
      font-size: 15px;
      line-height: 24px;
    }
  }
}

@media only screen and (min-width: 272px) and (max-width: 600px) {
  

  .root1 {
    display: flex;
    flex-direction: column;
  }
  .Tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 10px;
  }

  .Tab {
    display: flex;
    width: 40%;
    padding: 10px;
    border: 1px solid transparent;
    cursor: pointer;
    justify-content: center;
    transition: all 0.15s;
    &__selected {
      opacity: 1;
      background-color: #b7883c;
      border-bottom: 1px solid #b7883c;
    }

    &:hover {
      opacity: 0.9;
      color: #b7883c;
      // background-color: #B7883C;
    }

    &Label {
      font-weight: 700;
      font-size: 11px;
      line-height: 16px;
      color: #6c717a;

      &__selected {
        color: #fff;
      }
      &:hover {
        opacity: 0.9;
        // background-color: #B7883C;
      }
    }
  }
}
