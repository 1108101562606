.card {
  display: grid;
  grid-template-columns: 1fr;
  padding: 24px;
  background-color: #fff;
  border-radius: 2px;
  gap: 16px;
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #B7883C;
  width: 192px;
}
.subTitle {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #010101;
}

@media only screen and (min-width: 824px) and (max-width: 1100px) {
  .card {
    padding: 16px;
    gap: 16px;
  }

  .title {
    font-size: 19px;
    line-height: 32px;
    width: 144px;
  }

}
@media only screen and (min-width: 600px) and (max-width: 824px) {

  .card {
    display: grid;
    grid-template-columns: 1fr;
    padding: 12px;
    background-color: #fff;
    border-radius: 2px;
    gap: 16px;
  }

  .title {
    font-size: 17px;
    line-height: 25px;
    width: 100%;
  }

}
