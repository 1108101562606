.root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 80px;
  border-bottom: 1px solid #b0b0b0;
  padding-bottom: 100px;

  .title {
    font-weight: 800;
    font-size: 44px;
    line-height: 48px;
  }
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 48px;
    align-items: center;
  }
  .containerInter {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 48px;
    align-items: center;
  }
  .containerlast {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 48px;
    align-items: center;
  }
  .wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .subTitle {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #b7883c;
  }
  .text {
    font-weight: 400;
    font-size: 15px;
    line-height: 32px;
    color: #010101;
    text-align: justify;
  }
}

@media only screen and (min-width: 600px) and (max-width: 824px) {
  .root {
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;
    border-bottom: 1px solid #b0b0b0;
    padding-bottom: 40px;
    .title {
      font-weight: 700;
      font-size: 28px;
      line-height: 32px;
    }
    .container {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      align-items: center;
    }
    .containerlast {
      gap: 20px;
    }
    .containerInter {
      gap: 20px;
    }
    .wrap {
      display: flex;
      flex-direction: column;
      border: none;
      border-radius: 4px;
      padding: 16px;
      gap: 20px;
    }
    .subTitle {
      font-weight: 700;
      font-size: 19px;
      line-height: 30px;
    }
    .text {
      font-size: 13px;
      line-height: 18px;
    }
  }
}

@media only screen and (min-width: 272px) and (max-width: 600px) {
  .root {
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;
    border-bottom: 1px solid #b0b0b0;
    padding-bottom: 40px;
    .title {
      font-weight: 700;
      font-size: 21px;
      line-height: 32px;
    }
    .container {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      gap: 30px;
      align-items: center;
    }
    .containerlast {
      display: flex;
      flex-direction: column-reverse;
      gap: 30px;
    }
    .containerInter {
      display: flex;
      flex-direction: column-reverse;
      gap: 30px;
    }
    .wrap {
      display: flex;
      flex-direction: column;
      border: 1px solid #b7883c;
      border-radius: 4px;
      padding: 16px;
      gap: 20px;
    }
    .subTitle {
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
    }
    .text {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
