.root {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    gap: 20px;
    margin: 10px;
    padding-left: 20px;
    padding-right: 20px;
}
.left{
  border: 1px solid #b7883c;
}

.img{
    width: 400px;
    height: 400px;
}

.rigth{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 10px;
    padding-right: 10px;
    gap: 40px;
    input {
      border: 1px solid #cfd1d4;
      border-radius: 4px;
      color: #3f4753;
      padding: 16px 12px;
    }
    input:focus {
      border: 1px solid #b7883c;
    }
}

.form{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .color {
    color: #b7883c;
  }

  .btn{
  }

  @media only screen and (min-width: 320px) and (max-width: 600px) {
    .root {
      display: grid;
      grid-template-columns: 1fr;
      justify-content: space-between;
      gap: 20px;
      margin: 10px;
      padding-left: 20px;
      padding-right: 20px;
  }
  .left{
    border: 1px solid #b7883c;
  }
  
  .img{
      width: 300px;
      height: 300px;
  }
  
  .rigth{
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 10px;
      padding-right: 10px;
      gap: 40px;
      input {
        border: 1px solid #cfd1d4;
        border-radius: 4px;
        color: #3f4753;
        padding: 16px 12px;
      }
      input:focus {
        border: 1px solid #b7883c;
      }
  }
  
  .form{
      display: flex;
      flex-direction: column;
      gap: 10px;
  }
  
  .block {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    
    .color {
      color: #b7883c;
    }
  
    .btn{
    }
  }