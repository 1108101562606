.root {
  margin-top: 80px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 60px;
  padding-bottom: 100px;
  border-bottom: 1px solid #b0b0b0;
}
.container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-content: space-between;
  align-items: flex-start;
  .title {
    font-weight: 700;
    font-size: 44px;
    line-height: 48px;
    color: #b7883c;
  }
  .description {
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    color: #6c717a;
    text-align: justify;
  }
}
.img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
}
.slider {
  width: 100%;
  position: relative;
  height: 680px;
}
.swiper {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-button {
  position: absolute;
  top: 90%;
  z-index: 1000;
  cursor: pointer;
}
.swiper-button svg {
  width: 1.5rem;
  height: 1.5rem;
}
.image-swiper-button-prev {
  left: 90%;
}
.image-swiper-button-next {
  right: 30px;
}
.swiper-button-disabled {
  opacity: 0.5;
}

@media only screen and (min-width: 600px) and (max-width: 824px) {
  .root {
    margin-top: 60px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    padding-bottom: 40px;
    border-bottom: 1px solid #b0b0b0;
  }
  .container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-content: space-between;
    align-items: flex-start;
    .title {
      font-size: 28px;
      line-height: 36px;
    }
    .description {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #6c717a;
      text-align: justify;
    }
  }
  .slider {
    height: 354px;
  }
  .swiper-button {
    top: 88%;
  }
  .image-swiper-button-prev {
    left: 82%;
  }
}

@media only screen and (min-width: 272px) and (max-width: 600px) {
  .root {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 28px;
    padding-bottom: 40px;
    border-bottom: 1px solid #b0b0b0;
  }
  .container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 28px;

    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
    }
    .description {
      border: 1px solid #b7883c;
      border-radius: 4px;
      padding: 12px;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
    }
  }
  .img {
    width: 100%;
    height: 186px;
    background-repeat: no-repeat;
  }
  .slider {
    width: 100%;
    position: relative;
    height: 260px;
    margin-top: -10px;
  }
  .swiper {
    width: 100%;
    height: 100%;
  }
  .icon {
    width: 20px;
    height: 20px;
  }
  .swiper-button {
    position: absolute;
    top: 80%;
    z-index: 1000;
    cursor: pointer;
  }
  .image-swiper-button-prev {
    left: 73%;
  }
  .image-swiper-button-next {
    right: 24px;
  }
}
