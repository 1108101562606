
.root{
  display: flex;
  flex-direction: column;
  gap: 60px;
  border-bottom: 1px solid #B0B0B0;
  padding-bottom: 100px;
}
.title {
  font-weight: 700;
  font-size: 44px;
  line-height: 48px;
}
.img {
  width: 100%;
  height: 100%;
}
// .img1{
//   background-color: #B0B0B0;
//   width: 888px;
//   height: 888px;
// }
.TabsPage {
  display: flex;
  flex-direction: column;

  gap:80px;
  &Content {
    transition: 0.4ms;
    transition: ease-in;
  .containerImg{
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 20px 100px;
    border: 1px solid #B7883C;
    transition: 0.4ms;
    transition: ease-in;
  }
}
}

@media only screen and (min-width: 600px) and (max-width: 824px){
  .root{
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-bottom: 40px;
  }
  .title {
    font-size: 28px;
    line-height: 32px;
  }
  .TabsPage {
    display: flex;
    flex-direction: column;
    gap:25px;
    &-Content {
      transition: 0.4ms;
      transition: ease-in;
    .containerImg{
      display: flex;
      width: 100%;
      justify-content: center;
      padding: 12px 24px;
      border: 1px solid #B7883C;
      transition: 0.4ms;
      transition: ease-in;
    }
  }
  }

  .img{
    display: flex;
    width: 512px;
    height: 380px;
  }
}

@media only screen and (min-width: 272px) and (max-width: 600px){

  .root{
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-bottom: 1px solid #B0B0B0;
    padding-bottom: 40px;
  }
  .title {
    font-weight: 700;
    font-size: 19px;
    line-height: 48px;
  }
  .TabsPage {
    display: flex;
    flex-direction: column;
    gap:30px;
    &-Content {
      transition: 0.4ms;
      transition: ease-in;
    .containerImg{
      display: flex;
      width: 100%;
      justify-content: center;
      padding: 12px 24px;
      border: 1px solid #B7883C;
      transition: 0.4ms;
      transition: ease-in;
    }
  }
  }

  .img{
    display: flex;
    width: 266px;
    height: 198px;
  }
}


