.root {
  left: 0;
  bottom: 0;
  // max-width: 100vmax !important;;
  background: #333;
  display: grid;
  justify-content: center;
}
.wrap {
  width: 1104px;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  padding: 50px 0px 0;
  justify-items: center;
  gap: 30px;
  
}
.container{
  display: flex;
  flex-direction: row ;
  justify-content: space-between;
  align-items: center;
  gap: 90px;
}
.horizontal {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  gap: 32px;
}
.social{
  display: flex;
  flex-direction: row;
  gap: 12px;
  height: 24px;
}
.dashed {
  width: 100%;
  border-top: 3px solid #fff;
  border-radius: 5px;
}

.logo {
  display: flex;
  align-items: center;
  width: 86px;
  height: 87px;
}
.link{
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  cursor: pointer;
}
.change {
  display: flex;
  flex-direction: row;
  gap: 80px;
}

@media only screen and (min-width: 824px) and (max-width: 1100px) {
  
  .root {
    left: 0;
    bottom: 0;
    background: #333;
    display: grid;
    justify-content: center;
  }
  .wrap {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    padding: 60px 0px 40px 0px;
    justify-items: center;
    gap: 40px;
    
  }
  .container{
    display: flex;
    flex-direction: row ;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
  }
  .horizontal {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    gap: 15px;
  }
  .logo {
    display: flex;
    align-items: center;
    width: 127px;
    height: 17px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 824px) {
  
  .root {
    left: 0;
    bottom: 0;
    background: #333;
    display: grid;
    justify-content: center;
  }
  .logoSomon{
    width: 87px;
    height: 87px;
  }
  .wrap {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    padding: 60px 0px 40px 0px;
    justify-items: center;
    gap: px;
    
  }
  .container{
    display: flex;
    flex-direction: row ;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
  }
  .horizontal {
    width: 300px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .logo {
    display: flex;
    align-items: center;
    width: 127px;
    height: 17px;
  }
}

@media only screen and (min-width: 272px) and (max-width: 600px) {
  .root {
    height: auto;
    margin-top: 40px;
  }
  .wrap {
    width: 328px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;

  }
  .group {
    flex-direction: column;
    gap: 30px;
  }
  .horizontal {
    order: 2;
  font-weight: 800;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
}
.link{
  font-weight: 800;
font-size: 13px;
line-height: 20px;
}

}