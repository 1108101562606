.root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  z-index: 8999 !important;
  position: relative;
}
.header {
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrap {
  position: relative;
  width: 1104px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; // gap: 35px;
}

.header {
  width: 100%;
  display: grid;
  float: right;
}

.header ul {
  color: #fff;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
}
.button {
  margin-left: 60px;
}

.header li {
  display: block;
  padding: 5px 5px;
  text-decoration: none;
}
.header li {
  float: left;
}
.header li a {
  padding: 15px 15px;
}

.header li a:hover,
.header .menuBtn:hover {
  background-color: #b7883c;
}
.widthLogo {
  width: 100px;
  height: 99px;
}

.header .logo {
  margin-top: 6px;
  display: block;
  float: left;
  padding: 10px 1px;
  text-decoration: none;
}
.header .menu {
  display: flex;
  clear: both;
  transition: max-height 0.2s ease-out;
  flex-direction: row;
  align-items: center;
}

@media only screen and (min-width: 820px) and (max-width: 1100px) {
  .wrap {
    width: 824px;
    gap: 24px;
  }
  .header ul {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
  }
  .button {
    margin-left: 20px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 820px) {
  .wrap {
    width: 580px;
    gap: 23px;
  }
  .header ul {
    font-weight: 600;
    font-size: 10px;
    line-height: 20px;
  }
  .button {
    margin-left: 16px;
  }

  .header li a {
    padding: 5px 5px;
  }
  .widthLogo {
    width: 67px;
    height: 68px;
  }
}



@media only screen and (min-width: 250px) and (max-width: 600px) {
  .wrap {
    display: flex;
    justify-content: flex-start;
    width: 352px;
    gap: 0spx;
  }
  .None {
    display: none;
  }
  .buttons {
    display: none;
  }
  .widthLogo {
    width: 60px;
    height: 61px;
  }
}