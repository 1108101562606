.logoback {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;

  background-image: url("/assets/logoback.png");
  background-repeat: no-repeat;
  background-size: cover;
  // background-position: center;
  height: 670px;
  width: 668px;
}

.root {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: 60px;
  padding-bottom: 120px;
  border-bottom: 1px solid #b0b0b0;
}
.cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: max-content;
  gap: 48px;
  width: 100%;
}

.title {
  font-weight: 700;
  font-size: 44px;
  line-height: 48px;
}

@media only screen and (min-width: 600px) and (max-width: 824px) {
  .root {
    gap: 52px;
    padding-bottom: 60px;
  }
  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }

  .title {
    font-size: 28px;
    line-height: 32px;
  }
}

@media only screen and (min-width: 272px) and (max-width: 600px) {
  //create class logoback for mobile devices and devices  
  .root {
    display: grid;
    grid-template-columns: 1fr;
    gap: 28px;
    padding-bottom: 40px;
    border-bottom: 1px solid #b0b0b0;
  }
  .logoback {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
  
    background-image: url("/assets/logoback.png");
    background-repeat: no-repeat;
    background-size: cover;
    // background-position: center;
    height: 396px;
    width: 268px;
  }
  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow-wrap: normal;
    gap: 20px;
  }

  .title {
    font-weight: 700;
    font-size: 19px;
    line-height: 32px;
    color: #b7883c;
  }
}
