.root {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 60px;
  padding-bottom: 100px;
  overflow: hidden;
}
.card {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 48px;
}
.title {
  display: flex;
  font-weight: 700;
  font-size: 44px;
  line-height: 48px;
}
.maps {
  width: 100%;
}

.wrapform {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 48px;
  border: 1px solid #b7883c;
  border-radius: 4px;
  gap: 80px;

  .form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 28px;
  }
  .btn {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .formContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
  }
  input {
    border: 1px solid #cfd1d4;
    border-radius: 4px;
    color: #3f4753;
    padding: 16px 12px;
  }
  input:focus {
    border: 1px solid #b7883c;
  }
  textarea {
    border: 1px solid #cfd1d4;
    border-radius: 4px;
    color: #3f4753;
    padding: 16px 12px;
    resize: vertical;
    min-height: 160px;
    max-height: 400px;
  }
  textarea:focus {
    border: 1px solid #b7883c;
  }
  .titleForm {
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    color: #b7883c;
  }
}

@media only screen and (min-width: 600px) and (max-width: 824px) {
  .root {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
    padding-bottom: 100px;
  }
  .card {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 32px;
  }
  .title {
    width: 100%;
    font-size: 28px;
    line-height: 32px;
  }
  .maps {
    width: 100%;
  }
  .wrapform {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    padding: 0px;
    border: 0px;
    border-radius: 0px;
    gap: 36px;

    .form {
      display: grid;
      grid-template-columns: 1fr;
      gap: 28px;
    }
    .btn {
      margin-top: 24px;
      display: flex;
      justify-content: center;
      font-weight: 700;
      font-size: 28px;
      line-height: 32px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .formContainer {
      display: grid;
      grid-template-columns: 1fr;
      gap: 60px;
    }
    input {
      border: 1px solid #cfd1d4;
      border-radius: 4px;
      color: #3f4753;
      padding: 16px 12px;
    }
    input:focus {
      border: 1px solid #b7883c;
    }
    textarea {
      border: 1px solid #cfd1d4;
      border-radius: 4px;
      color: #3f4753;
      padding: 16px 12px;
      resize: vertical;
      min-height: 160px;
      max-height: 200px;
    }
    textarea:focus {
      border: 1px solid #b7883c;
    }
    .titleForm {
      font-weight: 700;
      font-size: 32px;
      line-height: 36px;
      color: #b7883c;
    }
  }
}

@media only screen and (min-width: 272px) and (max-width: 600px) {
  .img {
    width: 100vw;
  }
  .root {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-bottom: 50px;
  }
  .card {
    width: 100%;
    gap: 16px;
  }
  .title {
    width: 100%;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
  }
  .maps {
    width: 100%;
  }
  .wrapform {
      overflow: visible;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    padding: 0px;
    border: 0px;
    border-radius: 0px;
    gap: 36px;
    .formContainer {
      display: grid;
      grid-template-columns: 1fr;
      gap: 48px;
    }
    .form {
      display: grid;
      grid-template-columns: 1fr;
      // width: 380px;
      justify-content: center;
      align-items: center;
      gap: 28px;
    }
    .btn {
      margin-top: 24px;
      display: flex;
      justify-content: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    input {
      border: 1px solid #cfd1d4;
      border-radius: 4px;
      color: #3f4753;
      padding: 16px 12px;
      width: 100%;
    }
    input:focus {
      border: 1px solid #b7883c;
    }
    textarea {
      border: 1px solid #cfd1d4;
      border-radius: 4px;
      color: #3f4753;
      padding: 16px 12px;
      resize: vertical;
      min-height: 160px;
      max-height: 200px;
      width: 100%;
    }
    textarea:focus {
      border: 1px solid #b7883c;
    }
    .titleForm {
      font-weight: 700;
      font-size: 32px;
      line-height: 36px;
      color: #b7883c;
    }
  }
}



@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .form {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 28px;
  }

  .card {
    display: grid;
    gap: 5px;
  }
}
