.root{
  display: flex;
  flex-direction: column;
  gap: 60px;
  border-bottom: 1px solid #B0B0B0;
  padding-bottom: 100px;
}
.title {
  font-weight: 700;
  font-size: 44px;
  line-height: 48px;
}

.card{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;
}
.TabsPage {
  display: flex;
  flex-direction: column;
  gap:80px;
  &-Content {
    transition: 0.4ms;
    transition: ease-in;
  .containerImg{
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 20px 100px;
    border: 1px solid #B7883C;
    transition: 0.4ms;
    transition: ease-in;
  }
}
}


@media only screen and (min-width: 600px) and (max-width: 824px) {
  .root{
    display: flex;
    flex-direction: column;
    gap: 40px;
    border-bottom: 1px solid #B0B0B0;
    padding-bottom: 40px;
  }
  .title {
    font-weight: 700;
    font-size: 44px;
    line-height: 48px;
  }
  
  .card{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 48px;
  }
  .TabsPage {
    display: flex;
    flex-direction: column;
    gap:40px;
    &-Content {
      transition: 0.4ms;
      transition: ease-in;
    .containerImg{
      display: flex;
      width: 100%;
      justify-content: center;
      padding: 12px;
      border: 1px solid #B7883C;
      transition: 0.4ms;
      transition: ease-in;
    }
  }
  }
}

@media only screen and (min-width: 272px) and (max-width: 600px) {
  .root{
    display: flex;
    flex-direction: column;
    gap: 40px;
    border-bottom: 1px solid #B0B0B0;
    padding-bottom: 40px;
  }
  .title {
    font-weight: 700;
    font-size: 44px;
    line-height: 48px;
  }
  
  .card{
    display: grid;
    grid-template-columns: 1fr;
    gap: 48px;
  }
  .TabsPage {
    display: flex;
    flex-direction: column;
    gap:40px;
    &-Content {
      transition: 0.4ms;
      transition: ease-in;
    .containerImg{
      display: flex;
      width: 100%;
      justify-content: center;
      padding: 12px;
      border: 1px solid #B7883C;
      transition: 0.4ms;
      transition: ease-in;
    }
  }
  }
}