.root {
    width: 100%;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    max-height: 100%;
    .wrap{
      width: 1104px;
      display: flex;
      flex-direction: column;
      path {
        color: #fff;
      }
      gap: 100px;
    }

}

  @media only screen and (min-width: 824px) and (max-width: 1100px) {
    .root{
      overflow: visible;  
      background-position: center;
      max-height: 100%;
    .wrap{
      width: 824px;
      display: flex;
      gap: 80px;
    }
  }
  }

  @media only screen and (min-width: 600px) and (max-width: 824px) {
    .root{
      overflow: visible;  
      background-position: center;
      max-height: 100%;
    .wrap{
      width: 580px;
      display: flex;
      gap: 40px;
    }
  }
  }

@media only screen and (min-width: 360px) and (max-width: 600px) {
  .root{
    overflow: visible;  
    max-height: 100%;
      .wrap{
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    gap: 50px;
  }
}
}

@media only screen 
and (device-width: 428px) 
and (device-height: 926px) 
and (-webkit-device-pixel-ratio: 3) { 
  .root{
    overflow: visible;  
    max-height: 100%;
      .wrap{
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    gap: 55px;
  }
}
}

 

