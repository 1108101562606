.root {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.container {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.title {
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  color: #111928;
}

.data {
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: rgba(27, 31, 59, 0.8);
}
